<template>
  <div class="w_material_list">
    <div class="top_box">
      <div class="keyword_box">
        <Keyword @GoSearch="GoSearch"></Keyword>
      </div>
      <div class="filter_box">
        <FilterSort :title="'类型'" v-model:selected="data.selected_category" :list="data.list_category" v-if="data.list_category!=''"></FilterSort>
        <FilterSort :title="'排序'" v-model:selected="data.selected_sort" :list="data.list_sort"></FilterSort>
      </div>
    </div>

    <div class="material_list">
      <MaterialList :keyword="data.keyword" :selected_category="data.selected_category" :selected_sort="data.selected_sort"></MaterialList>
    </div>

  </div>
</template>

<script setup>
import { reactive, getCurrentInstance } from 'vue';
import Keyword from '../../components/Common/Keyword.vue';
import MaterialList from '../../components/Course/Listcom.vue'
import FilterSort from '../../components/Common/Filter.vue'

const _this = getCurrentInstance().appContext.config.globalProperties;

const data = reactive({
  list_sort: [{ id: '', title: '默认' }, { id: '1', title: '最新发布' }, { id: '2', title: '最多学习' }],
  selected_sort:'',
  list_category:[],
  selected_category:'',
  keyword: ''
});

function GoSearch(e){
  data.keyword = e;
}

function GetCourseCategory() {
  var url = "/Open/GetCourseCategory";
  _this.$http.post(url).then(function (res) {
    if (res.data.status == 1) {
      data.list_category = res.data.ds;
      data.list_category.unshift({ id: '', title: '全部课程' });
    }
  }.bind(this)).catch(function () {
  });
}

GetCourseCategory();


</script>

