<template>
  <div class="filterCon">
    <div class="l_label">{{ props.title }}</div>
    <div class="r_item">
      <div :class="['item', selected==item.id?'curr':'']" v-for="(item,index) in list" :key="index" @click="SelectItem(item.id)">{{ item.title }}</div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';

const props = defineProps(['title','list','selected']);
const emit = defineEmits(['update:selected']);

function SelectItem(_id){
  emit('update:selected', _id);
}

</script>